<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import useProducts from "../../../hooks/useProducts";
import { onBeforeMount } from "vue";
import { useRoute } from "vue-router/auto";
import ProductLayout from "../../../layouts/ProductLayout.vue";
import { setPageTitle } from "../../../utils/pageTitle";
import { useThemeStore } from "@/stores/themeStore";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const { fetchProduct, fetchInventory, product, inventory } = useProducts();

const route = useRoute();
const themeStore = useThemeStore();

let gridApi;

onBeforeMount(async () => {
  await fetchProduct(route.params.id);
  setPageTitle(`Product -${product.value.id}`);
});

const onGridReady = async (params) => {
  gridApi = params.api;
  let res = await fetchInventory(route.params.id);
  gridApi.setGridOption("rowData", inventory.value);
};

const columnDefs = [
  { field: "warehouse_name", headerName: "Warehouse", cellDataType: "text" },
  { field: "bin_name", headerName: "BIN", cellDataType: "text" },
  { field: "pallet_qty", headerName: "Pallets Qty", cellDataType: "text" },
  { field: "case_qty", headerName: "Case Qty", cellDataType: "text" },
];

const defaultColDef = {
  sortable: true,
  suppressHeaderMenuButton: true,
  width: 120,
};

const gridOptions = {
  autoSizeStrategy: {
    type: "fitGridWidth",
    defaultMinWidth: 100,
  },
};
</script>

<template>
  <div id="grid-wrapper">
    <ProductLayout :product="product">
      <div class="ml-4 mb-4">
        <h4>Inventory</h4>
      </div>
      <div class="inventory-table d-flex">
        <AgGridVue
          style="width: 100%; height: 100%"
          :class="themeStore.agTheme"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
        >
        </AgGridVue>
      </div>
    </ProductLayout>
  </div>
</template>

<style scoped>
.inventory-table {
  height: 600px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
